<template>
    <Teleport to="#teleports">
        <div :class="[modalWrapClass, !modal.active && 'hidden']">
            <div :class="['modal absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-5 md:p-9 w-full max-h-[calc(100%_-_40px)] overflow-auto no-scrollbar rounded-[20px]', modalWidth ? modalWidth : 'max-w-[900px]']">
                <slot></slot>
                <slot name="header"></slot>
                <slot name="body"></slot>
                <slot name="footer"></slot>
            </div>
        </div>
    </Teleport>
</template>

<script setup>

const props = defineProps(['modalWidth'])
const modalWrapClass = "modal-wrap fixed z-10 top-0 left-0 h-full w-full bg-black bg-opacity-50 backdrop-blur-sm";
const modal = reactive({
    active: true,
});

</script>

<style></style>